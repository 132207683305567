function getUrlVars() {
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}
window.urlVars = getUrlVars();
var thisloc = window.urlVars['thisloc'] ? window.urlVars['thisloc'] : '';

$(function() {
    const iguanaSearchTriggers = document.querySelectorAll('[data-iguana-search]');

    var ist = Array.prototype.slice.call(iguanaSearchTriggers);

    ist.forEach(function(el) {
        el.addEventListener('submit', function(evt) {
            const searchUrl = el.dataset.searchUrl.replace("%THISLOC%", "thisloc=" + thisloc);
            const searchValue = el.querySelector('[data-type="search-value"]');

            if (el != null) {
                const value = searchValue.value;

                if (typeof startApplication === 'function') {
                    evt.preventDefault();

                    startApplication('C:\\Program Files\\Google\\Chrome\\Application\\chrome.exe', searchUrl + value, '--new-window');
                    startApplication('C:\\Program Files (x86)\\Google\\Chrome\\Application\\chrome.exe', searchUrl + value, '--new-window');
                    searchValue.value = '';
                } else {
                    return true;
                }
                return false;
            }
            return false;
        });
    });

    const generalSearchTriggers = document.querySelectorAll('[data-general-search]');

    var gst = Array.prototype.slice.call(generalSearchTriggers);

    gst.forEach(function(el) {
        el.addEventListener('submit', function(evt) {
            const searchUrl = el.dataset.searchUrl;
            const searchValue = el.querySelector('[data-type="search-value"]');

            if (el != null) {
                const value = searchValue.value;
                if (typeof startApplication === 'function') {
                    evt.preventDefault();

                    startApplication('C:\\Program Files\\Google\\Chrome\\Application\\chrome.exe', searchUrl + value, '--new-window');
                    startApplication('C:\\Program Files (x86)\\Google\\Chrome\\Application\\chrome.exe', searchUrl + value, '--new-window');
                    searchValue.value = '';
                } else {
                    return true;
                }
                return false;
            }

            return false;
        });
    });

    $.extend( $.ui.tooltip.prototype.options.classes, {
        "ui-tooltip": "ui-corner-all"
    });

    const tooltipTriggers = document.querySelectorAll('[data-trigger-tooltip]');

    var ttt = Array.prototype.slice.call(tooltipTriggers);

    ttt.forEach(function(el) {
        $(el).tooltip({
            tooltipClass: el.dataset.tooltipClassappend != null ? el.dataset.tooltipClassappend : "",
            items: '#' + el.id,
            content: el.dataset.triggerTooltip
        });
    })
});
